import React, { createContext, useContext } from 'react';
import useLogin from '../hooks/useLogin';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const auth = useLogin();

    return (
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);