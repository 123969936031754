import { useState, useEffect } from 'react';
import { fetchTours } from '../services/apiService';

const useTours = () => {
    const [tours, setTours] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const cacheKey = 'tours_cache';
    const cacheExpirationKey = 'tours_cache_expiration';
    const cacheExpiration = 3600 * 1000; // 1 hora em milissegundos

    const fetchAndCacheTours = async () => {
        const cachedData = localStorage.getItem(cacheKey);
        const cachedExpiration = localStorage.getItem(cacheExpirationKey);
        const currentTime = new Date().getTime(); 
        // cache dos passeios, remover o comentário das linha abaixo
        /*if (cachedData && cachedExpiration && currentTime < parseInt(cachedExpiration, 10)) {
            // Cache ainda válido
            setTours(JSON.parse(cachedData));
            setLoading(false);
            return;
        }*/

        // Cache expirado ou não existe
        try {
            const data = await fetchTours();
            localStorage.setItem(cacheKey, JSON.stringify(data));
            localStorage.setItem(cacheExpirationKey, (currentTime + cacheExpiration).toString());
            setTours(data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAndCacheTours();
    }, []);

    const reload = () => {
        // Limpar cache e recarregar
        localStorage.removeItem(cacheKey);
        localStorage.removeItem(cacheExpirationKey);
        fetchAndCacheTours();
    };

    return { tours, loading, error, reload };
};

export default useTours;
