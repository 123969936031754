import React, { Suspense, lazy, useEffect, startTransition } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import AuthGuard from '../components/AuthGuard';

const LoadingSpinner = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div className="spinner"></div>
    </div>
);

// Carregando componentes de forma assíncrona
const loadable = (importFunc) => {
    return lazy(importFunc);
};
const Home = loadable(() => import('../pages/Home'));
const Preview = loadable(() => import('../pages/Preview'));
const Login = loadable(() => import('../pages/Login'));
const AboutUs = loadable(() => import('../pages/AboutUs'));
const Advertise = loadable(() => import('../pages/Advertise'));
const TermsOfUse = loadable(() => import('../pages/TermsOfUse'));
const PrivacyPolicy = loadable(() => import('../pages/PrivacyPolicy'));
const PrivacyCookie = loadable(() => import('../pages/PrivacyCookie'));
const Contact = loadable(() => import('../pages/Contact'));
const FAQ = loadable(() => import('../pages/FAQ'));
const Account = loadable(() => import('../pages/Account/Index'));
const Notifications = loadable(() => import('../pages/Account/Notifications'));
const Subscriptions = loadable(() => import('../pages/Account/Subscriptions'));
const Agenda = loadable(() => import('../pages/Account/Agenda'));
const AccountPlaces = loadable(() => import('../pages/Account/Places'));
const Financial = loadable(() => import('../pages/Account/Financial'));
const Security = loadable(() => import('../pages/Account/Security'));
const Help = loadable(() => import('../pages/Account/Help'));
const Logout = loadable(() => import('../pages/Account/Logout'));
const Register = loadable(() => import('../pages/Register'));
const Recovery = loadable(() => import('../pages/Recovery'));
const Profile = loadable(() => import('../pages/Account/Profile'));

const PrivateRoute = ({ element: Element, ...rest }) => {
    const { isLoggedIn } = useAuth();
    return isLoggedIn ? <Element {...rest} /> : <Navigate to="/login" />;
};

const RedirectToAccountIfLoggedIn = ({ element: Element, ...rest }) => {
    const { isLoggedIn } = useAuth();
    return !isLoggedIn ? <Element {...rest} /> : <Navigate to="/minha-conta" />;
};

const useAuthCheckOnRouteChange = () => {
    const { checkAuthStatus } = useAuth();
    const location = useLocation();

    useEffect(() => {
        // Usando startTransition para operações que podem causar suspensão
        startTransition(() => {
            checkAuthStatus();
            window.scrollTo(0, 0);
        });
    }, [location.pathname]);
};

const GetRoutes = () => {
    useAuthCheckOnRouteChange();
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/ver/:slug" element={<Preview />} />
                <Route path="/login" element={<RedirectToAccountIfLoggedIn element={Login} />} />
                <Route path="/registrar" element={<RedirectToAccountIfLoggedIn element={Register} />} />
                <Route path="/recuperar-conta" element={<RedirectToAccountIfLoggedIn element={Recovery} />} />
                <Route path="/minha-conta" element={<AuthGuard><PrivateRoute element={Account} /></AuthGuard>} />
                <Route path="/minha-conta/notificações" element={<AuthGuard><PrivateRoute element={Notifications} /></AuthGuard>} />
                <Route path="/minha-conta/assinaturas" element={<AuthGuard><PrivateRoute element={Subscriptions} /></AuthGuard>} />
                <Route path="/minha-conta/agenda" element={<AuthGuard><PrivateRoute element={Agenda} /></AuthGuard>} />
                <Route path="/minha-conta/meus-programas" element={<AuthGuard><PrivateRoute element={AccountPlaces} /></AuthGuard>} />
                <Route path="/minha-conta/financeiro" element={<AuthGuard><PrivateRoute element={Financial} /></AuthGuard>} />
                <Route path="/minha-conta/perfil" element={<AuthGuard><PrivateRoute element={Profile} /></AuthGuard>} />
                <Route path="/minha-conta/segurança" element={<AuthGuard><PrivateRoute element={Security} /></AuthGuard>} />
                <Route path="/minha-conta/ajuda" element={<AuthGuard><PrivateRoute element={Help} /></AuthGuard>} />
                <Route path="/minha-conta/sair" element={<Logout />} />
                <Route path="/quem-somos" element={<AboutUs />} />
                <Route path="/anuncie" element={<Advertise />} />
                <Route path="/termos-de-uso" element={<TermsOfUse />} />
                <Route path="/politica-de-privacidade" element={<PrivacyPolicy />} />
                <Route path="/politicas-de-cookie" element={<PrivacyCookie />} />
                <Route path="/fale-conosco" element={<Contact />} />
                <Route path="/faqs" element={<FAQ />} />
            </Routes>
        </Suspense>
    );
};

export const AppRoutes = () => (
    <Router>
        <GetRoutes />
    </Router>
);