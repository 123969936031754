import React, { createContext, useContext } from 'react';
import useCategories from './../hooks/useCategories';
import useTours from '../hooks/useTours';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const { categories, loading: loadingCategories, error: errorCategories, reload: reloadCategories } = useCategories();
    const { tours, loading: loadingTours, error: errorTours, reload: reloadTours } = useTours();

    return (
        <DataContext.Provider value={{
            categories,
            tours,
            loadingCategories,
            loadingTours,
            errorCategories,
            errorTours,
            reloadCategories,
            reloadTours
        }}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = () => useContext(DataContext);
