import { useState, useEffect, useCallback } from 'react';
import { fetchCategories } from '../services/apiService';

const useCategories = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const cacheKey = 'categories_cache';
    const cacheExpirationKey = 'categories_cache_expiration';
    const cacheExpiration = 3600 * 1000; // 1 hora em milissegundos

    const fetchAndCacheCategories = useCallback(async () => {
        const cachedData = localStorage.getItem(cacheKey);
        const cachedExpiration = localStorage.getItem(cacheExpirationKey);
        const currentTime = new Date().getTime();

        if (cachedData && cachedExpiration && currentTime < parseInt(cachedExpiration, 10)) {
            setCategories(JSON.parse(cachedData));
            setLoading(false);
            return;
        }

        try {
            const data = await fetchCategories();
            localStorage.setItem(cacheKey, JSON.stringify(data));
            localStorage.setItem(cacheExpirationKey, (currentTime + cacheExpiration).toString());
            setCategories(data);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, [cacheKey, cacheExpirationKey, cacheExpiration]);

    useEffect(() => {
        fetchAndCacheCategories();
    }, [fetchAndCacheCategories]);

    const reload = () => {
        // Clear the cache and refetch
        localStorage.removeItem(cacheKey);
        localStorage.removeItem(cacheExpirationKey);
        fetchAndCacheCategories();
    };

    return { categories, loading, error, reload };
};

export default useCategories;