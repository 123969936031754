import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  const { setIsLoggedIn } = useAuth();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.valid_until > new Date().getTime()) {
      setIsLoggedIn(true);
    } else {
      localStorage.removeItem('user');
      setIsLoggedIn(false);
      navigate('/login');
    }
  }, [navigate, setIsLoggedIn]);

  return <>{children}</>;
};

export default AuthGuard;
